import React, { useEffect, useState } from 'react';
import './App.css'; // Make sure to create an App.css file for styling
import { ChakraProvider } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'

// Import your images here
import background0 from './background0.png'; // Replace with actual path
import background1 from './background1.png'; // Replace with actual path
import background2 from './background2.png'; // Replace with actual path
import chartIcon from './chart.png'; // Replace with actual path
import telegramIcon from './tg.png'; // Replace with actual path
import lockIcon from './lock.png';
import swapIcon from './swap.png';
import xIcon from './x.png';

const backgrounds = [background0, background1, background2];

function App() {

  const toast = useToast();

  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBackgroundIndex((currentBackgroundIndex + 1) % backgrounds.length);
    }, 6666); // Change every 6 seconds

    return () => clearInterval(interval);
  }, [currentBackgroundIndex]);

  return (
    <ChakraProvider>
      <div className="App" style={{ backgroundImage: `url(${backgrounds[currentBackgroundIndex]})` }}>
        <header className="App-header">
          <h1 style={{ cursor: 'pointer' }} onClick={() => {
            navigator.clipboard.writeText('0xe9f5b061385d5c6f96fa5f4729c474a1abeda21b');
            toast({
                title: 'Copied!',
                description: '',
                duration: 3333,
                status: 'info',
                position: 'top-right',
            })
          }}>$BAPTIST - 0xe9f5b061385d5c6f96fa5f4729c474a1abeda21b</h1>
        </header>
        <main className='main'>
          <div className="button-container">
            <div className='btn'>
              <a href="https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0xe9f5b061385d5c6f96fa5f4729c474a1abeda21b&use=v2&chain=mainnet" target="_blank" rel="noopener noreferrer">
                <img src={swapIcon} alt="" />
                <span>Buy</span>
              </a>
            </div>
            <div className='btn'>
              <a className='btn' href="https://memedefi.io/ethereum/0x9ec1a518E8A0e0E12CB4C36Cb4803b640D50A04C" target="_blank" rel="noopener noreferrer">
                <img src={chartIcon} alt="" />
                <span>View Chart</span>
              </a>
            </div>
            <div className='btn'>
              <a className='btn' href="https://t.me/BaptistEntry" target="_blank" rel="noopener noreferrer">
                <img src={telegramIcon} alt="" />
                <span>Join Telegram</span>
              </a>
            </div>
            <div className='btn'>
              <a href="https://x.com/baptistwtf" target="_blank" rel="noopener noreferrer">
                <img src={xIcon} alt="" />
                <span>X</span>
              </a>
            </div>
            <div className='btn'>
              <a href="https://memedefi.io/ethereum/0x9ec1a518E8A0e0E12CB4C36Cb4803b640D50A04C" target="_blank" rel="noopener noreferrer">
                <img src={lockIcon} alt="" />
                <span>LP Lock</span>
              </a>
            </div>
          </div>
          <div className='scripture'>
            <span>Brethren, embrace the divine mission of </span><span style={{ color: 'rgba(0,240,255,1)' }}>$BAPTIST</span><span>, honoring John the Baptist, the herald of </span><span style={{ color: 'gold' }}>$JESUS</span><span>. 🕊️</span>
            <br/>
            <br/>
            <span style={{ fontSize: '28px' }}>Behold!</span>
            <br/>
            <span>Each transaction enacts a sacred rite: 1.5% is gathered — half to proclaim our gospel throughout the nations, the other half offered up to the purifying flames! 🔥</span>
            <br/>
            <br/>
            <span>As John prepared the way for $JESUS, we too sanctify our path, baptizing our endeavors in the holy drip of the blockchain heavens! 💧</span>
            <br/>
            <br/>
            <span>LP Locked ~ Renounced ~ Tax 1.5/1.5</span>
            <br/>
            <br/>
            <span>0.75% burn on every TX ♨️</span>
          </div>
        </main>
        <a style={{ textDecoration: 'none', fontWeight: 700, fontSize: '22px', marginBottom: '8px' }} href="mailto:thedudewithshades@gmail.com">thedudewithshades@gmail.com</a>
      </div>
    </ChakraProvider>
  );
}

export default App;
